body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  /* background: white url('/img/46NLogo.png') no-repeat fixed center;
  background-attachment: fixed;
  background-size: 85vw;
  background-position: center; */
  background-color: rgb(36, 49, 37);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  position: relative;
  min-height: 100vh;
  width: 100vw;
}

.app > * {
  max-width: 95%;
  max-height: 75%;
  display: flex;
  justify-content: center;
}

#footer{
  width: 100%;
}
.card-sub-holder {
  max-width: 80%;
}

/* .repair {
  position: absolute;
  left: 45vw; 
  top: 55vh;
  width: 18vw; 
  height: 18vh;
} */

